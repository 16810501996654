import React, {useRef, useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import './style.css';
import Results from "./Results";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useParams} from "react-router-dom";


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

interface
TabPanelProps
{
    children ? : React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


async function GetSession({kwId}) {
    const response = await fetch('https://xu8192x2rk.execute-api.eu-central-1.amazonaws.com/getStripePaymentUrl?' +
        'kw_id=' + kwId, {
        method: 'GET',
        headers: {'APIkey': 'CMmyPILZvBZ589OI0Ax9J3hs'}
    });
    const data = await response.json();


    return data;
}

async function GetKWData(session_id) {
    console.log(session_id && session_id);
    const response = await fetch('https://xu8192x2rk.execute-api.eu-central-1.amazonaws.com/kw?' +
        'session_id=' + session_id, {
        method: 'GET',
        headers: {'APIkey': 'CMmyPILZvBZ589OI0Ax9J3hs'}
    });
    const data = await response.json();
	if (data.kw_data) {
		if (typeof window !== "undefined" && window.gtag) {
			console.log('event_pushed')
		  window.gtag('event', 'conversion', {
			'send_to': 'AW-16813318719/J_FmCLzGtfQZEL_Em9E-',
			'value': 9.99,
			'currency': 'PLN',
			'transaction_id': '',
		  });
		}
	}
    return data;
}

const sectionIndex = {
    0: 'dzial_i_o.html',
    1: 'dzial_i_sp.html',
    2: 'dzial_ii.html',
    3: 'dzial_iii.html',
    4: 'dzial_iv.html'
}

function Kw() {
    const {height, width} = useWindowDimensions();
    // const [kwId, setKwId] = useState('PO1R/00050032/3');
    const [kwData, setKwData] = useState(null);
    const [selectedTab, setSelectedTab] = React.useState(0);

    const sessionId = window.location.pathname.split('/')[2];

    useEffect(() => {
        const fetchKWData = async () => {
            const KWData = await GetKWData(sessionId);
            setKwData(KWData.kw_data);
        }
        fetchKWData();
    }, []);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    return (
        <>
            <div style={{width: 1200, 'padding-left': (width - 1200) / 2}}>
                <Box style={{'padding-left': 320, width: 1200}} sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs style={{height: 60, fontSize: 18}} value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                        <Tab style={{height: 60, fontSize: 18}} label="Dział I-Os" {...a11yProps(0)} />
                        <Tab style={{height: 60, fontSize: 18}} label="Dział I-Sp" {...a11yProps(1)} />
                        <Tab style={{height: 60, fontSize: 18}} label="Dział II" {...a11yProps(2)} />
                        <Tab style={{height: 60, fontSize: 18}} label="Dział III" {...a11yProps(3)} />
                        <Tab style={{height: 60, fontSize: 18}} label="Dział IV" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <div style={{width: 1200}}>
                    <div style={{
                        'font-size': 20, 'border-collapse': 'collapse',
                        'box-shadow': '0 20px 20px rgba(0, 0, 0, 0.15)', 'padding': 10
                    }}
                         dangerouslySetInnerHTML={{__html: kwData && kwData[sectionIndex[selectedTab]]}}/>
                </div>
            </div>


        </>
    )
}

export default Kw;
